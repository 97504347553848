// ../../../react-shim.js
import React from "react";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");

// src/breadcrumb.tsx
import { getValidChildren } from "@chakra-ui/react-children-utils";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";
import { cloneElement } from "react";

// src/breadcrumb-context.ts
import { createContext } from "@chakra-ui/react-context";
var [BreadcrumbStylesProvider, useBreadcrumbStyles] = createContext({
  name: `BreadcrumbStylesContext`,
  errorMessage: `useBreadcrumbStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Breadcrumb />" `
});

// src/breadcrumb.tsx
var Breadcrumb = forwardRef(function Breadcrumb2(props, ref) {
  const styles = useMultiStyleConfig("Breadcrumb", props);
  const ownProps = omitThemingProps(props);
  const {
    children,
    spacing = "0.5rem",
    separator = "/",
    className,
    listProps,
    ...rest
  } = ownProps;
  const validChildren = getValidChildren(children);
  const count = validChildren.length;
  const clones = validChildren.map((child, index) => cloneElement(child, {
    separator,
    spacing,
    isLastChild: count === index + 1
  }));
  const _className = cx("chakra-breadcrumb", className);
  return /* @__PURE__ */ React.createElement(chakra.nav, {
    ref,
    "aria-label": "breadcrumb",
    className: _className,
    __css: styles.container,
    ...rest
  }, /* @__PURE__ */ React.createElement(BreadcrumbStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra.ol, {
    className: "chakra-breadcrumb__list",
    ...listProps,
    __css: {
      display: "flex",
      alignItems: "center",
      ...styles.list
    }
  }, clones)));
});
Breadcrumb.displayName = "Breadcrumb";

// src/breadcrumb-item.tsx
import {
  chakra as chakra4,
  forwardRef as forwardRef4
} from "@chakra-ui/system";
import { getValidChildren as getValidChildren2 } from "@chakra-ui/react-children-utils";

// src/breadcrumb-separator.tsx
import {
  chakra as chakra2,
  forwardRef as forwardRef2
} from "@chakra-ui/system";
var BreadcrumbSeparator = forwardRef2(function BreadcrumbSeparator2(props, ref) {
  const { spacing, ...rest } = props;
  const styles = useBreadcrumbStyles();
  const separatorStyles = {
    mx: spacing,
    ...styles.separator
  };
  return /* @__PURE__ */ React.createElement(chakra2.span, {
    ref,
    role: "presentation",
    ...rest,
    __css: separatorStyles
  });
});
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";

// src/breadcrumb-link.tsx
import { chakra as chakra3, forwardRef as forwardRef3 } from "@chakra-ui/system";
var BreadcrumbLink = forwardRef3(function BreadcrumbLink2(props, ref) {
  const { isCurrentPage, as, className, href, ...rest } = props;
  const styles = useBreadcrumbStyles();
  const sharedProps = {
    ref,
    as,
    className: cx("chakra-breadcrumb__link", className),
    ...rest
  };
  if (isCurrentPage) {
    return /* @__PURE__ */ React.createElement(chakra3.span, {
      "aria-current": "page",
      __css: styles.link,
      ...sharedProps
    });
  }
  return /* @__PURE__ */ React.createElement(chakra3.a, {
    __css: styles.link,
    href,
    ...sharedProps
  });
});
BreadcrumbLink.displayName = "BreadcrumbLink";

// src/breadcrumb-item.tsx
import { cloneElement as cloneElement2 } from "react";
var BreadcrumbItem = forwardRef4(function BreadcrumbItem2(props, ref) {
  const {
    isCurrentPage,
    separator,
    isLastChild,
    spacing,
    children,
    className,
    ...rest
  } = props;
  const validChildren = getValidChildren2(children);
  const clones = validChildren.map((child) => {
    if (child.type === BreadcrumbLink) {
      return cloneElement2(child, {
        isCurrentPage
      });
    }
    if (child.type === BreadcrumbSeparator) {
      return cloneElement2(child, {
        spacing,
        children: child.props.children || separator
      });
    }
    return child;
  });
  const styles = useBreadcrumbStyles();
  const itemStyles = {
    display: "inline-flex",
    alignItems: "center",
    ...styles.item
  };
  const _className = cx("chakra-breadcrumb__list-item", className);
  return /* @__PURE__ */ React.createElement(chakra4.li, {
    ref,
    className: _className,
    ...rest,
    __css: itemStyles
  }, clones, !isLastChild && /* @__PURE__ */ React.createElement(BreadcrumbSeparator, {
    spacing
  }, separator));
});
BreadcrumbItem.displayName = "BreadcrumbItem";
export {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  useBreadcrumbStyles
};
